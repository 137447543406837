<template lang="pug">
.history-row.flex.align-stretch
  .year.p-32.flex.align-center.justify-center {{ year }}
  .lightening.rwd-hide
  .desc.flex.column.p-32
    .line.text-left(v-for="line in desc", :key="line") {{ line }}
</template>

<script>
export default {
  props: {
    year: {
      type: Number,
      required: true,
    },
    desc: {
      type: Array,
      required: true,
    },
  },
};
</script>

  <style lang="scss" scoped>
  .history-row {
    position: relative;
    width: auto;
    column-gap: 52px;
    row-gap: 16px;
    width: 960px;

    border-radius: 16px;
    border: 1px solid var(--darkgray-20, #DEDDDD);
    background: #F1F1F1;

    .lightening {
      position: absolute;
      background-image: url('/svg/green_lightening.svg');
      width: 35px;
      height: 35px;
      top: 50%;
      left: 220px;
      transform: translate(-3px, -50%);
    }

    .year {
      flex: 0 0 220px;
      color: #282825;
      font-family: Poppins;
      font-size: 60px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 3px;
      background: white;
    }
    .desc {
      flex: 1;
      word-break: break-space;
      color: var(--text-color, #282825);
      font-family: Poppins;
      font-size: 22px;
      font-style: normal;
      line-height: 180%; /* 39.6px */
      letter-spacing: 0.22px;
      padding-left: 14px !important;
    }
  }

@media only screen and (max-width: 800px) {
  .history-row {
    flex-wrap: wrap;
    width: calc(100vw - 40px);
    gap: 0;
    .year {
      font-size: 40px;
      margin-left: 0;
      text-align: left;
      font-family: Poppins;
      flex: 0 0 100%;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }
    .desc {
      flex: 1 0 300px;
      font-size: 20px;
      padding: 12px 16px !important;
    }
  }
}
</style>
