<template lang="pug">
.company-intro
  page-block-title(
    title="關於 TOP TEAM"
    :subTitle="info.slogan"
    :desc="desc"
    hide-ellipse
    imageSrc="/images/title/introduction.png")
  .bg-image
  .history.flex.column.align-center.pb-140.pt-64.gap-32
    .company-card.flex.align-center.p-52.mb-60.gap-52.wrap
      .logo
      .text.flex.column.text-left
        .line(
          v-for="(t, idx) in text", :key="t") {{ t }}
    history-row(
      v-for="history in histories",
      :key="history.year", :year="history.year", :desc="history.content")
  page-footer
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import { GetCompanyInfo } from '@/api/company';
import HistoryRow from '../components/intro/HistoryRow.vue';

export default {
  components: {
    'history-row': HistoryRow,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters(['info', 'histories', 'text', 'desc', 'slogan']),
  },
  methods: {
    ...mapMutations(['setAnimateFinish', 'setInfo', 'setIntro']),
    async loadData() {
      const data = await GetCompanyInfo();
      this.setInfo(data.data);
      this.setIntro(data.data);
    },
  },
  mounted() {
    this.$setTitle('頂鑫科技工程有限公司/宥陞工程有限公司 - 公司介紹');
    this.loadData();
    this.setAnimateFinish(true);
  },
};
</script>

<style lang="scss" scoped>
.company-intro {
  position: relative;
  .bg-image {
    position: sticky;
    top: 0;
    height: 100vh;
    width: 100vw;
    margin-bottom: -100vh;
    background-image: url('/bg.png');
    background-size: cover;
  }
}
.history {
  .company-card {
    border-radius: 16px;
    background: var(--lightgreen, #BACF39);
    margin-left: calc(16px + 284 / 1065 * calc(100vw - 375px));
    margin-right: calc(16px + 284 / 1065 * calc(100vw - 375px));
    position: relative;
    .logo {
      background-image: url('/images/logo-large.svg');
      background-size: 161px 201px;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      width: 161px;
      height: 201px;
      flex: 0 0 161px;
      z-index: 2;
    }
    .text {
      flex: 1 0 300px;
      font-family: Noto Sans TC;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 180%; /* 28.8px */
      letter-spacing: 0.16px;
      z-index: 2;
    }
    .decorate {
      top: 96px;
      left: 30px;
      position: absolute;
      border-radius: 16px;
      border: 1px solid var(--lightgreen, #BACF39);
      width: 100%;
      height: calc(100% - 60px);
    }
  }
}

@media only screen and (max-width: 800px) {
  .company-intro {
    .company-card {
      flex-direction: column;
      margin: 16px !important;
      padding: 16px !important;
      .logo {
        flex: 0 0 201px !important;
      }
      .line {
        &.new-line {
          margin-top: 24px;
        }
      }
    }
  }
}
</style>
